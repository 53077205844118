import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommercialInsState, LocationObject } from "./types";
const initialState: CommercialInsState = {
  addressForm: {
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
    },
    isPrimary: true,
  },
  entityTypes: [],
  industryCodes: [],
  applicationFields: {
    applicationId: "",
    policyType: "",
    businessInfo: {
      businessName: "",
      yearOfFounding: "",
      naicsExtendedId: "",
      natureOfBusiness: "",
      legalEntityType: "",
    },
    uwqAnswers: [],
    uwsAnswers: [],
  },
  jobCodes: [],
  applicationQuestions: [],
  fieldsConfig: [],
  carrierIndustryCodes: [],
  uwStatements: [],
  applicationData: {},
};
export const commercial = {
  ...createSlice({
    name: "commercial",
    initialState: initialState,
    reducers: {
      resetState: () => initialState,
      setAddressForm(state, { payload }: PayloadAction<LocationObject>) {
        state.addressForm = payload;
      },
      setEntityTypes(state, { payload }: PayloadAction<any>) {
        state.entityTypes = payload;
      },
      setFieldsConfig(state, { payload }: PayloadAction<any>) {
        state.fieldsConfig = payload;
      },
      setCarrierIndustryCodes(state, { payload }: PayloadAction<any>) {
        state.carrierIndustryCodes = payload;
      },
      setJobCodes(state, { payload }: PayloadAction<any>) {
        const codes = payload.map((jc) => ({
          description: jc.description,
          code: jc.jobCode,
          codeExtended: jc.jobCodeId,
        }));
        state.jobCodes = codes;
      },
      setQuestions(state, { payload }: PayloadAction<any>) {
        state.applicationQuestions = payload;
      },
      setUwStatements(state, { payload }: PayloadAction<any>) {
        state.uwStatements = payload;
      },
      setApplicationData(state, { payload }: PayloadAction<any>) {
        state.applicationData = { ...payload.data };
      },
      setIndustryCodes(state, { payload }: PayloadAction<any>) {
        const codes = payload.map((ic) => ({
          description: ic.description,
          code: ic.naicsCode,
          codeExtended: ic.naicsCodeId,
        }));
        state.industryCodes = codes;
      },
      setApplicationFields(state, { payload }: PayloadAction<any>) {
        state.applicationFields = { ...state.applicationFields, ...payload };
      },
      setWizard(state, { payload }: PayloadAction<any>) {
        state.addressForm = payload.addressForm;
      },
    },
  }),
  persist: true,
};
