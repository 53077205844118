import { Navigation } from "../../lib";
import AdminPages from "../../pages/admin";
import { authClient, uiActions } from "../states";
import { commercial } from "./state";
const { actions } = commercial;

export const commercialActions = {
  ...actions,
  startApplication(form, req_uid) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(
        `/commercial/application/${req_uid}/start`,
        { ...form },
      );
      if (status === 204) {
        dispatch(uiActions.setLoading(false));
        Navigation.go(
          AdminPages.continueCommercial.path.replace(":id", req_uid),
        );
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to start application"));
      }
    };
  },
  addDetails(values, req_uid, getQuestions) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/commercial/${req_uid}/details`,
        values,
      );
      if (status === 204) {
        if (getQuestions) {
          Navigation.go(
            AdminPages.commercialQuestions.path.replace(":id", req_uid),
          );
        }
      } else {
        dispatch(uiActions.setLoading(false));
        if (data.message) {
          dispatch(uiActions.showError(data.message));
          return;
        }
        dispatch(uiActions.showError("Failed to update application"));
      }
    };
  },
  submitQuote(values, req_uid) {
    return async (dispatch, getState) => {
      const { applicationFields } = getState().commercial;
      const {
        buildingInfo,
        businessInfo,
        employeeInfo,
        pastPolicyLossInfo,
        employeeInfos,
        owners,
        coverageInfo,
      } = applicationFields;
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/commercial/${req_uid}/details`,
        {
          buildingInfo,
          businessInfo,
          employeeInfo,
          pastPolicyLossInfo,
          questions: values,
          employeeInfos,
          owners,
          coverageInfo,
          submit: true,
        },
      );
      if (status === 204) {
        Navigation.go(
          AdminPages.quote_mgmt_page.path.replace(":masterQuoteId", req_uid),
        );
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        if (data.message) {
          dispatch(uiActions.showError(data.message));
          return;
        }
        dispatch(uiActions.showError("Failed to submit application"));
      }
    };
  },
  getLegalEntities(req_uid) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/legal-entities`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setEntityTypes(data.legalEntityInfos));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get entity types"));
      }
    };
  },
  getFieldsConfig(req_uid) {
    return async (dispatch) => {
      dispatch(actions.setFieldsConfig([]));
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/fields-config`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setFieldsConfig(data.fieldsConfig));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get fields config"));
      }
    };
  },
  getIndustryCodes() {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setIndustryCodes([]));
      const { data, status } = await authClient.get(
        `/commercial/get-industry-codes`,
      );
      if (status === 200) {
        dispatch(actions.setIndustryCodes(data.naicsCodeInfos));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.showError("Failed to get industry codes"));
        dispatch(uiActions.setLoading(false));
      }
    };
  },
  getJobCodesByState(req_uid) {
    return async (dispatch) => {
      dispatch(actions.setJobCodes([]));
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/get-job-codes`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setJobCodes(data.jobCodeInfos));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get job codes"));
      }
    };
  },
  getCarrierIndustryCodes(req_uid) {
    return async (dispatch) => {
      dispatch(actions.setCarrierIndustryCodes([]));
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/get-carrier-industry-codes`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setCarrierIndustryCodes(data));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get carrier industry codes"));
      }
    };
  },
  getApplicationQuestions(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setQuestions([]));
      const { data, status } = await authClient.get(
        `/commercial/application/${quoteId}/questions`,
      );
      if (status === 200) {
        dispatch(actions.setQuestions(data));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.showError("Failed to get application questions"));
        dispatch(uiActions.setLoading(false));
      }
    };
  },
  getUwStatements(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setUwStatements([]));
      const { data, status } = await authClient.get(
        `/commercial/application/${quoteId}/statements`,
      );
      if (status === 200) {
        dispatch(actions.setUwStatements(data));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get underwriting statements"));
      }
    };
  },
  getApplicationData(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/application/${quoteId}/data`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setApplicationData(data));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get application data"));
      }
    };
  },
};
