import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import QuestionForm from "./Questionsform";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Form, yup } from "../../../lib";
import {
  commercialActions,
  commercialSelectors,
  uiSelectors,
  useAction,
} from "../../../state";
import { useParams } from "react-router-dom";
import { StatementsModal } from "./StatementsModal";
import { RelianceLoader } from "./CommercialContinueForm";
interface Data {
  [key: string]: string;
}

const splitFieldsByLocation = (data: Data, questionArray) => {
  return Object.entries(data).reduce(
    (acc, [key, value]) => {
      const question = questionArray.find((q) => q.questionId === key);
      if (question) {
        if (question.locationQuestion) {
          acc.locationUWInformation.push({ [key]: value });
        } else {
          acc.uwqAnswers.push({ [key]: value });
        }
      }
      return acc;
    },
    { locationUWInformation: [] as Data[], uwqAnswers: [] as Data[] },
  );
};
export const CommercialQuestions = () => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  // const initialAppFields = useSelector(commercialSelectors.applicationFields);
  // const initialFields = useSelector(commercialSelectors.applicationData);
  const applicationQuestions = useSelector(
    commercialSelectors.applicationQuestions,
  );
  const uwStatements = useSelector(commercialSelectors.uwStatements);
  const hasStatements = !!uwStatements.find(
    (st) => !!st.statementGroups.length,
  );
  const isLoading = useSelector(uiSelectors.loading);
  const setApplicationFields = useAction(
    commercialActions.setApplicationFields,
  );

  const [validationSchema, setValidationSchema] = useState<any>({});
  const [initialValues, setInitialValues] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [acceptedConditions, setAcceptedConditions] = useState(false);

  useEffect(() => {
    dispatch(commercialActions.getUwStatements(params.id));
    dispatch(commercialActions.getApplicationQuestions(params.id));
    dispatch(commercialActions.getApplicationData(params.id));
  }, [params.id]);
  useEffect(() => {
    if (!applicationQuestions?.length) {
      return;
    }
    const initialValues = applicationQuestions.reduce((values, question) => {
      values[question.questionId] =
        question.answerOptions.find(
          (ao) =>
            ao.answerOptionText.toLowerCase() ===
            question.defaultAnswer?.toLowerCase(),
        )?.answerOptionValue || "";
      return values;
    }, {});
    setInitialValues({
      ...initialValues,
    });
    const questionsValidationSchema = yup.object().shape({
      ...applicationQuestions.reduce((schema, question) => {
        if (question.validations) {
          question.validations.forEach((validation) => {
            if (
              validation.validationType === "REQUIRED" &&
              !question.hasParentQuestion
            ) {
              if (question.questionType === "MULTISELECTDROPDOWN") {
                schema[question.questionId] = yup
                  .array()
                  .of(yup.string())
                  .min(1, validation.validationErrorMessage)
                  .required(validation.validationErrorMessage);
              } else {
                schema[question.questionId] = yup
                  .string()
                  .required(validation.validationErrorMessage);
              }
            }
          });
        }
        return schema;
      }, {}),
    });
    setValidationSchema(questionsValidationSchema);
  }, [applicationQuestions]);
  const form: any = useFormik({
    initialValues: { ...initialValues } || {},
    enableReinitialize: true,
    validationSchema: validationSchema ? validationSchema : {},
    async onSubmit(values, { setSubmitting }) {
      const questions = splitFieldsByLocation(values, applicationQuestions);
      const applicationId = params.id;
      await setApplicationFields({ ...values, applicationId, questions });
      dispatch(commercialActions.submitQuote(questions, params.id));
      setSubmitting(false);
    },
  });
  return isLoading ? (
    <RelianceLoader />
  ) : (
    <Box>
      <Form form={form} className="maxWidth">
        <Typography variant="h4" sx={{ margin: "20px 0" }}>
          Questions and Statements
        </Typography>
        {!!applicationQuestions?.length && (
          <Box>
            <QuestionForm questions={applicationQuestions} />
            <StatementsModal
              data={uwStatements}
              open={open}
              setOpen={setOpen}
              setAcceptedConditions={setAcceptedConditions}
            />
            {hasStatements ? (
              <>
                <Button onClick={() => setOpen(true)} sx={{ margin: "20px 0" }}>
                  View and accept Policy statements
                </Button>
                <br />
              </>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ height: 44 }}
              disabled={hasStatements && !acceptedConditions}
            >
              Submit application
            </Button>
          </Box>
        )}
      </Form>
    </Box>
  );
};
