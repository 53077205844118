import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";
import { formatSplitCase } from "../../utils";
const _state = (state: AppState) => state.admin;
const _applications = createSelector(_state, (state) => {
  const useKeys = new Set();
  const applications = state.applications?.map((a) => {
    const key = `${a.first_name} ${a.last_name}/${
      a.addr1 ? `${a.addr1}${a.state}${a.city}${a.zip}` : `no address`
    }`;
    const path = useKeys.has(key) ? [key, a.id] : [key];
    useKeys.add(key);
    return {
      ...a,
      agencyName: a.agency?.name,
      // state: a.state,
      customerName: a.first_name + " " + a.last_name,
      affiliate_agent: a.affiliate_agent.first_name
        ? a.affiliate_agent.first_name + " " + a.affiliate_agent.last_name
        : "N/A",
      fullStatus: a.status ? a.status + " - " + a.sub_status : "",
      status: a.status ? a.status : "",
      quote_type: a.commercial_type
        ? "commercial"
        : a.quote_types?.includes("auto") && a.quote_types?.includes("home")
        ? "bundle"
        : a.quote_types?.includes("auto") && a.quote_types?.includes("renters")
        ? "autoRenters"
        : a.quote_types?.includes("auto")
        ? "auto"
        : a.quote_types?.includes("home")
        ? "home"
        : a.quote_types?.includes("renters")
        ? "renters"
        : a.quote_types?.includes("life")
        ? "life"
        : a.quote_types !== null
        ? "manual"
        : "",
      status_last_updated: a.last_updated,
      path,
    };
  });

  return applications;
});
export const adminSelectors = {
  pageTitle(state: AppState) {
    return state.admin.pageTitle;
  },
  activeQuote(state: AppState) {
    const types = Object.keys(state.admin.activeQuote?.quotes).map((level) => {
      if (state.admin.activeQuote?.baseInfo) {
        return "Life";
      }
      if (state.admin.activeQuote?.quotes[0]?.manual_quote_type) {
        return "manual";
      }
      if (
        state.admin.activeQuote?.quotes[level].find(
          (quote) => quote.quote_type === "auto",
        ) &&
        state.admin.activeQuote?.quotes[level].find(
          (quote) => quote.quote_type === "renters",
        )
      ) {
        return "AutoRenters";
      } else if (
        state.admin.activeQuote?.quotes[level].find(
          (quote) => !!quote.is_linked,
        )
      ) {
        return "Bundle";
      } else if (
        state.admin.activeQuote?.quotes[level].find(
          (quote) => quote.quote_type === "auto",
        )
      ) {
        return "Auto";
      } else if (
        state.admin.activeQuote?.quotes[level].find(
          (quote) => quote.quote_type === "home",
        )
      ) {
        return "Home";
      } else if (
        state.admin.activeQuote?.quotes[level].find(
          (quote) => quote.quote_type === "renters",
        )
      ) {
        return "Renters";
      } else {
        return "";
      }
    });
    return {
      ...state.admin.activeQuote,
      quoteType: types.find((x) => !!x) || "",
      quoteTypes: types,
    };
  },
  loadingNewDriverQuotes(state: AppState) {
    return state.admin.loadingNewDriverQuotes;
  },
  pmtInfo(state: AppState) {
    return state.admin.pmtInfo;
  },
  showOfflinePaymentLink(state: AppState) {
    return state.admin.showOfflinePaymentLink;
  },
  users(state: AppState) {
    const users = state.admin.users.rows.map((u) => ({
      ...u,
      rolesAsString:
        u.user_agency?.map((ua) => formatSplitCase(ua.role)) ||
        (u.sales_director && formatSplitCase(u.sales_director[0].role)),
      agencyName: u.user_agency?.map((ua) => ua.agency?.name),
      referralToken: u.user_agency?.map((ua) => ua.referral_token),
    }));
    return users;
  },
  faqs(state: AppState) {
    return state.admin.faqs?.rows;
  },
  carriers(state: AppState) {
    return state.admin.carriers?.rows.map((r) => ({
      ...r,
      is_active: r.agency_group_carriers[0].is_active,
      agency_group_id: r.agency_group_carriers[0].id,
    }));
  },
  manualCarriers(state: AppState) {
    return state.admin.manualCarriers?.rows;
  },
  availableCarriers(state: AppState) {
    return state.admin.availableCarriers;
  },
  referralProducts(state: AppState) {
    return state.admin.products?.rows.map((p) => ({
      ...p,
      category: p.referral_category.name,
      logo: p.logo_full_url,
    }));
  },
  agencies(state: AppState) {
    const agencies = state.admin.agencies?.rows.map((a) => ({
      ...a,
      groupName: a.agency_group?.name,
    }));
    return agencies;
  },
  agencyModalOpen(state: AppState) {
    return state.admin.agencyModalOpen;
  },
  agentTransferModalOpen(state: AppState) {
    return state.admin.agentTransferModalOpen;
  },
  assignReferrerModalOpen(state: AppState) {
    return state.admin.assignReferrerModalOpen;
  },
  carrierModalOpen(state: AppState) {
    return state.admin.carrierModalOpen;
  },
  emailModalOpen(state: AppState) {
    return state.admin.emailModalOpen;
  },
  activityModalOpen(state: AppState) {
    return state.admin.activityModalOpen;
  },
  emailModalData(state: AppState) {
    return state.admin.emailModalData;
  },
  uploadModalOpen(state: AppState) {
    return state.admin.uploadModalOpen;
  },
  carrierNotes(state: AppState) {
    return state.admin.carrierNotes;
  },
  activeAgencyData(state: AppState) {
    return state.admin.activeAgencyData;
  },
  agency_groups(state: AppState) {
    return state.admin.agency_groups?.rows;
  },
  user_agencies(state: AppState) {
    return state.admin.user_agencies?.rows?.map((r) => ({
      ...r,
      role: formatSplitCase(r?.role || ""),
    }));
  },
  states(state: AppState) {
    return state.admin.states?.rows;
  },
  coverage(state: AppState) {
    return state.admin.coverage ? state.admin.coverage.rows : [];
  },
  coverage_ui(state: AppState) {
    return state.admin.coverage_ui ? state.admin.coverage_ui.rows : [];
  },
  allowedIPs(state: AppState) {
    return state.admin.allowedIPs ? state.admin.allowedIPs.rows : [];
  },
  faqCategories(state: AppState) {
    return state.admin.faqCategories?.rows;
  },
  applications: _applications,
  referrals(state: AppState) {
    const referrals = state.admin.referrals?.rows.map((r) => ({
      ...r,
      name: r.first_name + " " + r.last_name,
      category: r.product_data.referral_category?.name,
      productName: r.product_data.product_name,
      referringAgent: r.referring_agent
        ? r.referring_agent?.user.first_name +
          " " +
          r.referring_agent?.user.last_name
        : "None",
    }));
    return referrals;
  },
  referralData(state: AppState) {
    return state.admin.referralData;
  },
  uddRequests(state: AppState) {
    const uddRequests = state.admin.uddRequests?.rows.map((udd) => ({
      ...udd,
      agencyName: udd.customer?.agency?.name,
    }));
    return uddRequests;
  },
  reports(state: AppState) {
    return state.admin.reports?.rows;
  },
  selectedAgencyGrp(state: AppState) {
    return state.admin.selectedAgencyGrp;
  },
  selectedCoverage(state: AppState) {
    return state.admin.selectedCoverage;
  },
  currentUser(state: AppState) {
    return state.admin.currentUser;
  },
  directors(state: AppState) {
    return state.admin.directors;
  },
  quoteStatus(state: AppState) {
    return state.admin.quoteStatus;
  },
  bundleBindData(state: AppState) {
    return state.admin.bundleBindData;
  },
  listModalOpen(state: AppState) {
    return state.admin.listModalOpen;
  },
  bindModalOpen(state: AppState) {
    return state.admin.bindModalOpen;
  },
  taskModalOpen(state: AppState) {
    return state.admin.taskModalOpen;
  },
  taskModalData(state: AppState) {
    return state.admin.taskModalData;
  },
  agents(state: AppState) {
    return state.admin.agents;
  },
  referralAgents(state: AppState) {
    return state.admin.referralAgents;
  },
  agentStats(state: AppState) {
    return state.admin.agentStats;
  },
  events(state: AppState) {
    return state.admin.events;
  },
  schedule(state: AppState) {
    return state.admin.schedule;
  },
  alerts(state: AppState) {
    return state.admin.alerts;
  },
  googleCalendars(state: AppState) {
    return state.admin.googleCalendars;
  },
  hasOAuthAccessToken(state: AppState) {
    return state.admin.hasOAuthAccessToken;
  },
};
