import React from "react";
import {
  Box,
  Card,
  Grid,
  Link,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { adminSelectors, useSelector } from "../../../state";
import { CFResponse } from "./types";
import { InfoIcon, LaunchIcon } from "../../../components";
import { formatCurrency } from "../../../utils";

export const CommercialCoverageSection = () => {
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const policyType = activeQuote.applicant.commercial_type;
  const quotes = activeQuote.quotes[0];
  return (
    <Box>
      <Typography sx={{ marginBottom: "20px", fontWeight: 600 }}>
        Policy type {policyType}
      </Typography>
      {!!quotes.length &&
        quotes.map((q, index) => {
          const responsePayload: CFResponse = q.response_payload;
          const { failures, quoteStatus } = responsePayload;
          const logo = `https://cf-static-assets-snp.api.coverforce.com/carriers/images/${q.manual_carrier_name.toLowerCase()}_logo.png`;

          return (
            <Card
              key={index}
              sx={{
                padding: "20px",
                marginBottom: "20px",
                borderRadius: "4px",
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      height: "20px",
                      maxWidth: "110px",
                      marginBottom: "10px",
                    }}
                  >
                    <img src={logo} alt="carrier_logo" height={"25px"} />
                  </Box>
                  <Typography>{quoteStatus.statusCode}</Typography>
                </Box>
                <Box>
                  {q.total_premium > 0 ? (
                    <Box>
                      <Typography sx={{ fontWeight: 500 }}>
                        Premium: {formatCurrency(Math.round(q.total_premium))}
                      </Typography>
                      <Link target="_blank" href={q.quote_url}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography>View in carrier</Typography>
                          <LaunchIcon />
                        </Box>
                      </Link>
                    </Box>
                  ) : (
                    <Box>
                      {" "}
                      <Grid
                        container
                        style={{ width: "auto" }}
                        alignItems="center"
                        gap={1}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#FF0000",
                            fontSize: 14,
                            textTransform: "uppercase",
                          }}
                        >
                          Quote Failed
                        </Typography>
                        <Tooltip
                          title={
                            <List>
                              {failures?.length
                                ? failures?.map((f, i) => (
                                    <ListItem key={i}>{f.reason}</ListItem>
                                  ))
                                : null}
                            </List>
                          }
                        >
                          <InfoIcon
                            sx={{
                              fontSize: 20,
                              color: "#8d98c2",
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            </Card>
          );
        })}
    </Box>
  );
};
