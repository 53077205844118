import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  TextField as MuiTextField,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form } from "../../../lib";
import {
  commercialActions,
  commercialSelectors,
  uiSelectors,
  useAction,
  useDispatch,
  useSelector,
} from "../../../state";
import { useParams } from "react-router-dom";
import {
  getFormFields,
  getSelectOptionByName,
  parseAndSplitCamelCase,
  snakeCaseToSentence,
} from "./fieldsUtils";
import { FormField } from "./FormField";
import { FieldsConfig } from "../../../state/commercial/types";
import reliExchangeLoader from "../../../assets/reliExchange-loader.gif";

export const RelianceLoader = () => {
  return (
    <Box
      sx={{
        pointerEvents: "none",
        height: "300px",
        width: "300px",
        display: "flex",
        margin: "0 auto",
      }}
    >
      <img
        src={reliExchangeLoader}
        style={{ marginBottom: 20, height: "200px" }}
      ></img>
    </Box>
  );
};
const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const EntityTypeSelect = ({ form, options, name }) => {
  return (
    <FormControl sx={{ width: "280px", height: "48px" }}>
      <InputLabel id="policy-type-label">Entity type</InputLabel>
      <Select
        label="Entity type"
        value={form.values.businessInfo?.legalEntityType || ""}
        onChange={(e) => form.setFieldValue(name, e.target.value)}
      >
        {options.map((o) => (
          <MenuItem value={o.id} key={o.id}>
            {o.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const GenericFormSelect = ({
  form,
  options,
  name,
  label,
  parseOptions = true,
}) => {
  const value = getNestedValue(form.values, name);
  return (
    <FormControl sx={{ width: "280px", height: "48px" }}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        label={label}
        value={value || ""}
        onChange={(e) => form.setFieldValue(name, e.target.value)}
      >
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            {parseOptions ? snakeCaseToSentence(option) : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ToggleField = ({ form, name, label }) => {
  const value = getNestedValue(form.values, name);
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        width: "280px",
      }}
    >
      <Typography>{label}</Typography>
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        onChange={(_e, value) => {
          form.setFieldValue(name, value);
        }}
      >
        <ToggleButton value={true} sx={{ height: "34px" }}>
          Yes
        </ToggleButton>
        <ToggleButton value={false} sx={{ height: "34px" }}>
          No
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export const CodesInput = ({
  form,
  codes,
  label,
  name,
}: {
  form: any;
  codes: {
    code: any;
    description: string;
    carrier?: string;
    industryCodeId?: string;
    codeExtended?: string;
  }[];
  label: string;
  name: string;
}) => {
  const formValue = getNestedValue(form.values, name);

return (
  <Autocomplete
    disableListWrap
    renderInput={(params) => (
      <MuiTextField {...params} value="" label={label} />
    )}
    onChange={(_e, v) => {
      if (name.includes("carrierIndustryCodes")) {
        form.setFieldValue(`${name}.carrierId`, v?.carrier);
        form.setFieldValue(`${name}.industryCodeId`, v?.industryCodeId);
        form.setFieldValue(`${name}.industryCodeDescriptionId`, v?.description);
      } else {
        form.setFieldValue(name, v?.codeExtended);
      }
    }}
    options={codes || []}
    sx={{
      maxWidth: "650px",
      padding: 0,
      "& .MuiInputBase-root": {
        padding: "0 8px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    }}
    value={
      (!name.includes("carrierIndustryCodes") &&
        codes.find((option) => option.codeExtended === formValue)) ||
      (typeof formValue === "object" &&
        codes.find(
          (option) => option.industryCodeId === formValue?.industryCodeId,
        )) ||
      null
    }
    isOptionEqualToValue={(option, value) => {
      if (typeof formValue === "string") {
        return option.code === value;
      }
      return option.industryCodeId === formValue?.industryCodeId;
    }}
    getOptionLabel={(option) => `${option.description} - ${option.code}`}
    renderOption={(props, option) => {
      return (
        <Typography
          {...props}
          key={option.code + option.description}
          sx={{ maxWidth: "600px" }}
        >
          {option.description} - {option.code}
        </Typography>
      );
    }}
  />
);
};

const extractInitialValues = (sections) => {
  const initialValues = {};
  sections.forEach((section) => {
    section.fields.forEach((field) => {
      const fullNamePath = field.fullName.split(".");
      fullNamePath.reduce((acc, key, index) => {
        if (index === fullNamePath.length - 1) {
          acc[key] = "";
        } else {
          if (!acc[key]) acc[key] = {};
        }
        return acc[key];
      }, initialValues);
    });
  });

  return initialValues;
};

export const CommercialContinueForm = () => {
  const setApplicationFields = useAction(
    commercialActions.setApplicationFields,
  );
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const [initialValues, setInitialValues] = useState<any>({});
  // const [validationSchema, setValidationSchema] = useState<any>({});
  const [sections, setSections] = useState<FieldsConfig[]>();

  useEffect(() => {
    dispatch(commercialActions.getLegalEntities(params.id));
    dispatch(commercialActions.getFieldsConfig(params.id));
    dispatch(commercialActions.getJobCodesByState(params.id));
    dispatch(commercialActions.getApplicationData(params.id));
    dispatch(commercialActions.getCarrierIndustryCodes(params.id));
    dispatch(commercialActions.getIndustryCodes());
  }, [params.id]);

  const entityOptions = useSelector(commercialSelectors.entityTypes);
  const fieldsConfig = useSelector(commercialSelectors.fieldsConfig);
  const industryCodes = useSelector(commercialSelectors.industryCodes);
  const carrierIndustryCodes = useSelector(
    commercialSelectors.carrierIndustryCodes,
  );
  const isLoading = useSelector(uiSelectors.loading);

  const jobCodes = useSelector(commercialSelectors.jobCodes);
  const applicationFields = useSelector(commercialSelectors.applicationFields);
  const initialData = useSelector(commercialSelectors.applicationData);
  // const {
  //   businessInfo,
  //   employeeInfo,
  //   pastPolicyLossInfo,
  //   buildingInfo,
  //   employeeInfos,
  //   owners,
  // } = initialData;

  const hasCarrierCodes = carrierIndustryCodes.find(
    (cic) => !!cic.codes.industryCodeInfos.length,
  );
  const policyType = applicationFields.policyType || initialData.policyType;

  useEffect(() => {
    if (fieldsConfig.length) {
      const _sections = getFormFields(fieldsConfig, policyType);
      setSections(_sections);
    }
  }, [fieldsConfig]);

  // const fieldsValidationSchema = fieldsConfig.reduce((acc, section) => {
  //   section.fields.forEach((field) => {
  //     let schema;
  //     switch (field.userInputDataType) {
  //       case 'STRING':
  //         schema = yup.string();
  //         break;
  //       case 'NUMBER':
  //         schema = yup.number();
  //         break;
  //       case 'BOOLEAN':
  //         schema = yup.boolean();
  //         break;
  //       default:
  //         schema = yup.mixed(); // fallback for unknown types
  //     }
  //     if (field.requirementLevel === 'REQUIRED') {
  //       schema = schema.required('Required');
  //     }
  //     acc[field.fullName] = schema;
  //   });
  //   return acc;
  // }, {});
  // );

  useEffect(() => {
    if (!sections?.length) {
      return;
    }
    const initialFields = extractInitialValues(sections);
    // const stateFields = {
    //   businessInfo,
    //   employeeInfo,
    //   pastPolicyLossInfo,
    //   buildingInfo,
    //   employeeInfos,
    //   owners,
    // };

    setInitialValues({
      ...initialFields,
      // ...stateFields,
    });
  }, [sections]);

  const form: any = useFormik({
    initialValues: { ...initialValues } || {},
    enableReinitialize: true,
    // validationSchema: validationSchema ? validationSchema : {},
    async onSubmit(values, { setSubmitting }) {
      await setApplicationFields(values);
      dispatch(commercialActions.addDetails(values, params.id, true));
      setSubmitting(false);
    },
  });

  return isLoading ? (
    <RelianceLoader />
  ) : Object.keys(initialValues).length > 0 ? (
    <Form form={form} className="maxWidth">
      {" "}
      <Box
        sx={{
          maxWidth: "650px",
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          marginTop: "24px",
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ margin: "20px 0" }}>
            Continue Application
          </Typography>
          {sections?.map((s) => {
            if (!s.fields?.length) return null;
            return (
              <Box sx={{ margin: "20px 0" }} key={s.node_name}>
                <Typography sx={{ fontWeight: 600 }}>{s.title}</Typography>
                <br />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                  {s.fields.map((f) => {
                    return (
                      <Box key={f.name}>
                        {(() => {
                          const jobCodeName =
                            f.name === "employeeJobClassCode"
                              ? "owners.jobCodeId"
                              : "employeeInfos.jobCodeId";
                          switch (f.name) {
                            case "legalEntityType":
                              return (
                                <EntityTypeSelect
                                  form={form}
                                  options={entityOptions}
                                  name={f.fullName}
                                />
                              );
                            case "isNonProfit":
                            case "isOwner":
                              return (
                                <ToggleField
                                  form={form}
                                  name={f.fullName}
                                  label={parseAndSplitCamelCase(f.name)}
                                />
                              );
                            case "roofType":
                            case "burglarAlarmTypeCode":
                            case "constructionTypeCode":
                              return (
                                <GenericFormSelect
                                  form={form}
                                  name={f.fullName}
                                  options={getSelectOptionByName(f.name)}
                                  label={parseAndSplitCamelCase(f.name)}
                                />
                              );
                            case "lossType":
                              return (
                                <GenericFormSelect
                                  form={form}
                                  name={f.fullName}
                                  options={getSelectOptionByName(
                                    f.name,
                                    policyType,
                                  )}
                                  label={parseAndSplitCamelCase(f.name)}
                                />
                              );
                            case "claimStatus":
                              return (
                                <GenericFormSelect
                                  form={form}
                                  name={f.fullName}
                                  options={getSelectOptionByName(f.name)}
                                  label={parseAndSplitCamelCase(f.name)}
                                />
                              );
                            case "state":
                              return (
                                <GenericFormSelect
                                  form={form}
                                  name={f.fullName}
                                  options={getSelectOptionByName(f.name)}
                                  label={parseAndSplitCamelCase(f.name)}
                                  parseOptions={false}
                                />
                              );
                            case "lossDate":
                              return (
                                <TextField
                                  label="Loss date"
                                  type="date"
                                  sx={{ minWidth: "280px" }}
                                  onChange={(e) =>
                                    form.setFieldValue(
                                      "pastPolicyLossInfo.lossDate",
                                      e.target.value,
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              );
                            case "jobCodeId":
                            case "employeeJobClassCode":
                              return (
                                <Box sx={{ minWidth: "280px" }}>
                                  <CodesInput
                                    form={form}
                                    codes={jobCodes}
                                    label={"Job Code"}
                                    name={jobCodeName}
                                  />
                                </Box>
                              );
                            default:
                              return (
                                <Box sx={{ maxWidth: "285px" }}>
                                  <FormField
                                    type={f.userInputDataType}
                                    label={parseAndSplitCamelCase(f.name)}
                                    fullName={f.fullName}
                                  />
                                </Box>
                              );
                          }
                        })()}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ margin: "20px 0" }}>
        <Typography sx={{ marginBottom: "10px" }}>
          Select a NAICS code
        </Typography>
        <CodesInput
          form={form}
          codes={industryCodes}
          label={"Select a NAICS code"}
          name={"businessInfo.naicsExtendedId"}
        />
      </Box>
      {hasCarrierCodes ? (
        <Box>
          <Typography sx={{ fontWeight: 600, marginBottom: "5px" }}>
            Carrier specific industry code
          </Typography>
          {carrierIndustryCodes?.map((cic) => {
            if (!cic.codes.industryCodeInfos.length) return null;
            return (
              <Box>
                <Typography>Industry code for {cic.carrier}</Typography>
                <Box sx={{ margin: "10px 0" }}>
                  <CodesInput
                    form={form}
                    codes={cic.codes.industryCodeInfos.map((ic) => ({
                      code: ic.naicsCodes.join(", "),
                      industryCodeId: ic.industryCodeId,
                      carrier: cic.carrier,
                      description: ic.industryCodeDescriptionId,
                    }))}
                    label={"Select code"}
                    name={`carrierIndustryCodes.${cic.carrier}`}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : null}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box>
          <Typography sx={{ marginBottom: "5px" }}>
            In a few words describe the business
          </Typography>
          <MuiTextField
            {...params}
            sx={{ maxWidth: "650px" }}
            value={form.values.businessInfo?.natureOfBusiness}
            onChange={(e) =>
              form.setFieldValue(
                "businessInfo.natureOfBusiness",
                e.target.value,
              )
            }
            label="Business description"
            multiline
            minRows={3}
          />
        </Box>
      </Box>
      <br />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        sx={{ height: 44 }}
      >
        Add Details
      </Button>
    </Form>
  ) : null;
};
